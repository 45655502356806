import { useEffect } from "react";
// eslint-disable-next-line no-restricted-imports
import { useDebouncedCallback as rawUseDebouncedCallback } from "use-debounce";
// use-debounce doesn't export these types for some reason...
import {
  DebouncedState,
  Options,
} from "use-debounce/dist/useDebouncedCallback";

export interface UseDebouncedCallbackOptions extends Options {
  /**
   * Specifies what should be done with any pending debounced updates when the
   * component calling this hook unmounts. The default behavior is to flush
   * pending updates.
   */
  onUnmount?: "flush" | "cancel";
}

/**
 * A thin wrapper around use-debounce's useDebouncedCallback which takes care
 * of wiring up a useEffect hook to handle when the component unmounts. By
 * default pending updates will be flushed to the underlying callback, with
 * the option to cancel instead.
 */
export function useDebouncedCallback<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends (...args: any[]) => ReturnType<T>,
>(
  callback: T,
  delayMs?: number,
  { onUnmount = "flush", ...options }: UseDebouncedCallbackOptions = {},
): DebouncedState<T> {
  const debounced = rawUseDebouncedCallback(callback, delayMs, options);
  useEffect(() => {
    return () => {
      if (onUnmount === "flush") {
        debounced.flush();
      } else if (onUnmount === "cancel") {
        debounced.cancel();
      }
    };
  }, [debounced, onUnmount]);
  return debounced;
}
